<template>
  <div :class="value.isHot ? 'background-danger' : 'background-success'">
    <div>
    <b-button v-if="isRemovable && !this.d_disabled" @click="showConfirmRemovalDialog=true" variant="warning" style="float: right; margin-top:-10px;" >Supprimer le terrain</b-button>

    <div v-if="showSummary">
      <h4 v-if="showSelector"><b-checkbox v-model="isSelected" @change="selector_change">{{ value.field_name }}</b-checkbox></h4>
      <h4 v-if="!showSelector">{{ value.field_name}}</h4>
      <p>{{ value.isSonorized ? ' (sonorisé)' : '(non sonorisé)'}}</p>
      <p>{{ value.size_A}}m x {{value.size_B}}m</p>
    </div>
    <div v-if="!showSummary">
      <b-form-group label="Nom">
        <b-input :readonly="d_disabled" type="text" v-model="value.field_name" @change="field_name_change" />
      </b-form-group>

      <b-form-group label="Taille" class="field_size">
        <b-input :readonly="d_disabled" type="number" class="field_size" v-model="value.size_A" @change="size_A_change" /><span class="field-size">m</span><span class="field-size">X</span><b-input type="number" :readonly="d_disabled" class="field_size" v-model="value.size_B" @change="size_B_change" /><span class="field_size">m</span>
      </b-form-group>

      <b-form-group label="Sonorisé">
        <b-radio-group :disabled="d_disabled" v-model="value.isSonorized" @change="isSonorized_change">
          <b-radio :value=true>Oui</b-radio>
          <b-radio :value=false>Non</b-radio>
        </b-radio-group>
      </b-form-group>
    </div>
    <b-row>
      <b-col md="6">
        <b-form-group label="Stage-IN">
          <b-input type="text" :readonly="d_disabled" v-model="value.stage_in" @change="stage_in_change" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Stage-OUT">
          <b-input type="text" :readonly="d_disabled" v-model="value.stage_out" @change="stage_out_change" />
        </b-form-group>
      </b-col>
    </b-row>
    <div v-show="value.isHot && !d_disabled">
      <b-button :disabled="d_disabled" @click="isHot_change(false)" size="lg" variant="danger" >LE TERRAIN EST CHAUD</b-button>
      <div v-if="canSetHotState && !d_disabled" style="font-size:0.9rem;" class="notice">Cliquez sur le bouton ci-dessus pour le passer à "froid"</div>
    </div>
    <div v-show="!value.isHot && !d_disabled">
      <b-button :disabled="d_disabled" @click="isHot_change(true)" size="lg" variant="success" >Le terrain est froid</b-button>
      <div v-if="canSetHotState && !d_disabled" style="font-size:0.9rem;" class="notice">Cliquez sur le bouton ci-dessus pour le passer à "chaud"</div>
    </div>

    <b-modal
      @ok="remove"
      ok-title="Oui"
      cancel-title="Non"
      cancel-variant="secondary"
      ok-variant="warning"
      title="Confirmation de suppression"
      v-model="showConfirmRemovalDialog"
    >
      Etes-vous certain de vouloir supprimer le terrain n° {{value.field_number}} ?
    </b-modal>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: { type:Object, required: true },
    canSetHotState: { type:Boolean, default: false},
    isRemovable: {type:Boolean, default:false},
    showSummary: { type:Boolean, default: false},
    showSelector: { type:Boolean, default:false },
    isSelected: { type:Boolean, default:false} ,
    disabled: { type:Boolean, default: false},
  },
  data(){
    return {
      showConfirmRemovalDialog: false,
      d_disabled: this.disabled,
    }
  },
  methods:{
    field_name_change(newValue){
      this.$emit("change", { field_number: this.value.field_number, property: 'field_name', value: newValue});
      this.$emit("fieldName_change", this.value.field_number, newValue);
    },
    size_A_change(newValue){
      this.$emit("change", { field_number: this.value.field_number, property: 'size_A', value: newValue});
      this.$emit('size_change', this.value.field_number, newValue, this.value.size_B);
    },
    size_B_change(newValue){
      this.$emit("change", { field_number: this.value.field_number, property: 'size_B', value: newValue});
      this.$emit('size_change', this.value.field_number, this.value.size_A, newValue);
    },
    isSonorized_change(newValue)
    {
      this.$emit("change", { field_number: this.value.field_number, propertu: 'isSonorized', value: newValue});
      this.$emit("isSonorized_change", this.value.field_number, newValue);
    },
    stage_in_change(newValue)
    {
      this.$emit("change", { field_number: this.value.field_number, property: 'stage_in', value: newValue});
      this.$emit("stageIn_change", this.value.field_number, newValue);
    },
    stage_out_change(newValue)
    {
      this.$emit("change", { field_number: this.value.field_number, property: 'stage_out', value: newValue});
      this.$emit("stageOut_change", this.value.field_number, newValue);
    },
    isHot_change(newValue)
    {
      if(this.canSetHotState && !this.d_disabled){
        this.$emit("change", { field_number: this.value.field_number, property: 'isHot', value: newValue });
        this.$emit("hotState_change", this.value.field_number, newValue);
      }
    },
    remove(e)
    {
      if(this.isRemovable)
      {
        e.preventDefault();
        this.$emit("removeField", this.value.field_number);
        this.showConfirmRemovalDialog = false;
      }
    },
    selector_change(newValue){
      this.$emit("selection_change", { field: this.value, isSelected: newValue });
    }
  },
  watch:{
    disabled: function(newValue){ this.d_disabled = newValue; }
  }
}
</script>

<style scoped>
  input.field_size{ max-width: 100px !important; }
  span.field-size{ margin-left: 5px; margin-right: 5px;  }
 /* .background-danger{ background-color: var(--danger); opacity: 0.8;}
  .background-success{ background-color: var(--success); opacity: 0.8; }*/
  /*.background-danger > div, .background-success > div { opacity: 1;}*/
</style>

