<template>
  <div>      
    <b-row>
      <b-col>
        <b-button @click="add" variant="secondary" v-if="canAdd && itemsList.length < maxMemberCount" :disabled="addButtonDisabled">
          <b-icon icon="person-plus-fill" />&nbsp;Ajouter
        </b-button>
      </b-col>
      <b-col>
        <slot name="headCommandLine"></slot>
      </b-col>
    </b-row>
        <b-table-lite small hover :items="itemsList" :fields="columns" class="thisTable" responsive ref="tableTeamMembers" :thead-class="headerClass" >

            <template #cell(order)="data">
              <b-badge pill variant="info" size="sm" v-show="data.item.people_id != null">{{data.item.order}}</b-badge>
            </template>

            <template #cell(searchPeopleCommand)="data">
              <b-icon icon="search" variant="success" @click="search(data.item)" />
            </template>
            <template #cell(editMemberCommand)="data">
              <b-icon icon="pencil-fill" variant="secondary" v-show="data.item.people_id != null" @click="edit(data.item)" />
            </template>
            <template #cell(name)="data">
              <span class="name" v-show="data.item.people_id != null">{{ data.item.name || (data.item.firstname + ' ' + data.item.lastname).trim() }}</span>
            </template>
            <template #cell(firstname)="data">
              <span v-show="data.item.people_id != null">{{data.item.firstname}}</span>
            </template>
            <template #cell(lastname)="data">
              <span v-show="data.item.people_id != null">{{data.item.lastname}}</span>
            </template>
            <template #cell(shortname)="data">
                <span v-show="data.item.people_id != null" class="shortname">{{ data.item.shortname }}</span>
            </template>
            <template #cell(federalNumber)="data">
                <span class="federalNumber" v-show="data.item.people_id != null" v-if="data.item.isFederal" :class="{'invalid': data.item.federalNumber.theme=='danger', 'warning': data.item.federalNumber.theme=='warning',  'isWithdrawn': data.item.isWithdrawn, 'isForfeited': data.item.isForfeited, 'isAbsent': data.item.isAbsent}">
                    {{ data.item.federalNumber.number }}
                </span>
                <b-badge v-show="data.item.people_id != null" v-if="data.item.isFederal && data.item.federalNumber.theme!=''" pill :variant="data.item.federalNumber.theme" @click="editLicensee(data.item)">!</b-badge>
            </template>
            <template #cell(removeMemberCommand)="data">
              <b-icon icon="trash-fill" variant="danger" v-show="data.item.people_id!=null" @click="remove(data.item)" />
            </template>
        </b-table-lite>
  </div>
</template>

<script>
import { LICENSEE, PEOPLE, RST_COMPETITION } from "@/LocalDB";

export default {
  props:{
      competitor: { type:Object, required: true },
      members: {type:Array, required: true},
      canAdd: { type: Boolean, default:false},
      canRemove: { type: Boolean, default:false},
      canEdit: { type: Boolean, default:false},
      showRollnumber: { type: Boolean, default:false},
      showName: { type: Boolean, default: false},
      showFirstname: { type: Boolean, default: false},
      showLastname: { type: Boolean, default: false},
      showShortname: { type: Boolean, default: false},
      showHeaders: { type: Boolean, default:true },
      showOrder: { type:Boolean, default: false},
      minMemberCount: { type:Number, required: true},
      maxMemberCount: { type:Number, required: true},
  },
  data(){
    return { 
      d_competitor: this.competitor,
      d_items: this.members,// (this.competitor != null) ? this.competitor.members : [] 
      itemsList: [],
    };
  },
  computed:{
    headerClass(){
        return this.showHeaders ? "" : "d-none";
    },
    columns(){
      var ret = [];
      if(this.canAdd)
          ret.push({ key: 'searchPeopleCommand', sortable: false, label: '', class:"selector", stickyColumn: true});
      if(this.canEdit)
          ret.push({ key:"editMemberCommand", sortable:false, label: ''});
      if(this.showOrder)
        ret.push({ key: 'order', sortable: false, label: 'N°', class:'order', stickyColumn: true});
      if(this.showName || (!this.showFirstname && !this.showLastname && !this.showShortname))
          ret.push({ key: 'name', sortable: false, label: 'Nom', class:"name", stickyColumn: true});
      if(this.showFirstname)
          ret.push({ key: 'firstname', sortable: false, label: 'Prénom', class:"firstname", stickyColumn: false});
      if(this.showLastname)
          ret.push({ key: 'lastname', sortable: false, label: 'Nom', class:"lastname", stickyColumn: true});
      if(this.showShortname)
          ret.push({ key: 'shortname', sortable: false, label: 'Nom court', class:"shortname", stickyColumn: true});
      if(this.showRollnumber)
          ret.push({ key: 'federalNumber', sortable: false, label: 'N° licence', class:"federalNumber"});
      if(this.canRemove)
          ret.push({ key:"removeMemberCommand", sortable:false, label: ''});
      return ret;
    },
    addButtonDisabled(){
      var nbUsedSlots = this.itemsList.filter(e => e.people_id && e.people_id != null).length;
      return (nbUsedSlots < this.minMemberCount);
    }
  },
  methods:{
    refresh(){
      //this.d_items = this.competitor ? this.competitor.members : [];
      console.log('refresh', { d_items: this.d_items})
      var ret=[];
      if(this.d_items == null && this.minMemberCount == 0)
        return ret;


      for(var i=0; i < this.minMemberCount; i++){
        var item = (this.d_items.length > i) ? this.d_items[i] : null;
        var isNew = item == null;
        if(isNew)
        {
          item = new PEOPLE();
          item.id = null;
        }
        var isFederal = (item.federalNumber != null && item.federalNumber != '');
        var fdn = (item.federalNumber && item.federalNumber.number) ? item.federalNumber.number : item.federalNumber;
        var licensee = isNew ? new LICENSEE() : item.LICENSEE;
        if(!isNew && !licensee ){
          // par défaut on prendra l'année en cours comme info de licence
          var year = new Date().getFullYear();
          if(item.competition_id){
            // Vu que l'on a un id de compétition, on va essayer de retrouver les infos de licence de la personne pour l'année de la compétition
            year = RST_COMPETITION.query().where('id', item.competition_id).first().year;
          }
          licensee = LICENSEE.query().where("federal_number", item.federalNumber).where("year", year).first() || new LICENSEE();
        }
        var theme = '';
        if(isFederal)
        {
          if(!licensee.isValid)
            theme = "danger";
          else if(!licensee.isCompetitor)
            theme = 'warning';
          else if(!licensee.isMedicalCertificateValid)
            theme = 'warning';
        }
        
          item = {...item,
              index: i,
              isFederal: isFederal,
              //people_id: item.people_id || item.id,
              
              federalNumber:
                  {
                    number: fdn,
                    isFederal: isFederal,
                    isValid: licensee.isValid,
                    isCompetitor: licensee.isCompetitor,
                    isMedicalCertificateValid: licensee.isMedicalCertificateValid,
                    theme: theme
                  }
          };
          ret.push(item);
      }
      for(i= this.minMemberCount; i < this.d_items.length; i++){
        item = this.d_items[i];
        isFederal = (item.federalNumber != null && item.federalNumber != '');
        fdn = (item.federalNumber && item.federalNumber.number) ? item.federalNumber.number : item.federalNumber;
        licensee = item.LICENSE;
        if(!licensee ){
          // par défaut on prendra l'année en cours comme info de licence
          year = new Date().getFullYear();
          if(item.competition_id){
            // Vu que l'on a un id de compétition, on va essayer de retrouver les infos de licence de la personne pour l'année de la compétition
            year = RST_COMPETITION.query().where('id', item.competition_id).first().year;
          }
          licensee = LICENSEE.query().where("federal_number", item.federalNumber).where("year", year).first() || new LICENSEE();
        }
        theme = '';
        if(isFederal)
        {
          if(!licensee.isValid)
            theme = "danger";
          else if(!licensee.isCompetitor)
            theme = 'warning';
          else if(!licensee.isMedicalCertificateValid)
            theme = 'warning';
        }
          item = {...item,
              index: i,
              isFederal: isFederal,
              //people_id: item.people_id || item.id,
              
              federalNumber:
                  {
                    number: fdn,
                    isFederal: isFederal,
                    isValid: licensee.isValid,
                    isCompetitor: licensee.isCompetitor,
                    isMedicalCertificateValid: licensee.isMedicalCertificateValid,
                    theme: theme
                  }
          };
          ret.push(item);  
      }
      this.itemsList = ret;
    },
    search(item){
      this.$emit("search", item);
    },
    add(){
      this.$emit("add");
    },
    edit(item){
      this.$emit("edit", item);
    },
    remove(item){
      this.$emit("remove", item);
    },
    editLicensee(competitor){
        this.$emit('editLicensee', competitor);
    },
    /*async refresh(){

    },
    async mounted(){
      await this.refresh();
    },*/

  },
  mounted(){
    this.refresh();
  },
  watch:{
    competitor: function(newVal){
      this.d_competitor = newVal;
      this.refresh();
    },
    members(newVal)
    {
      this.d_items = newVal;
      console.log('watch items', { newVal : newVal, d_items: this.d_items})
      this.refresh();
    }
  }
}
</script>

<style scoped>
  .thisTable{ width: 100% }

    .federalNumber.invalid{ color: var(--danger) !important; font-style: italic;  }
    .federalNumber.danger{ color: var(--danger) !important; font-style: italic;  }
    .federalNumber.warning{ color: var(--warning) !important; font-style: italic; }
    .isForfeited,.isAbsent,.isWithdrawn { text-decoration: line-through; color: #b7b7b7;}
    @media screen and (max-width: 767px) and (orientation: portrait) {
        th.federalNumber { font-size: 0.8rem; }
        td.federalNumber { font-size: 0.8rem; }
    }

</style>
